<template>
    <div>
    <!--    选择工区-->
        <div>
            <el-select v-model="bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 10%">
                <el-option
                    v-for="item in bdsts"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
            <el-button type="primary" size="small" style="margin-left: 2%" @click="save_jd()">添加审批人员</el-button>
        </div>
    <!--    当前审批人员列表-->
        <div>
            <el-table :data="user_list" style="width: 100%">
                <el-table-column prop="bdst" label="单位" width="180" />
                <el-table-column prop="userName" label="姓名" width="180" />
                <el-table-column prop="backReason" label="审批节点" width="180">
                    <template #default="scope">
                        <span v-if="scope.row.userType === 0">工区设备部</span>
                        <span v-if="scope.row.userType === 1">工区主管领导</span>
                        <span v-if="scope.row.userType === 2">项目公司设备部</span>
                        <span v-if="scope.row.userType === 3">项目公司分管</span>
                    </template>
                </el-table-column>
                <el-table-column prop="a" label="操作" >
                    <template #default="scope">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click.prevent="update_jd(scope.row)"> 修改节点 </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    <!--    新增弹窗-->
        <div>
            <el-dialog
                v-model="dialogVisible"
                title="新增审批节点"
                width="60%"
            >
                <div>
                    <div>
                        <el-tag>所属单位</el-tag>
                        <el-select v-model="save_bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 30%"
                        @change="getUserByBdst">
                            <el-option
                                v-for="item in bdsts"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>

                    <div style="margin-top: 3%">
                        <el-tag>审批人员</el-tag>
                        <el-select v-model="bdst_user" class="m-2" placeholder="请选择审批人员" size="small" style="width: 30%"
                                   @change="getUserByBdst">
                            <el-option
                                v-for="item in bdst_user_list"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id + ',' + item.name"
                            />
                        </el-select>
                    </div>

                    <div style="margin-top: 3%">
                        <el-tag>审批节点</el-tag>
                        <el-select v-model="jd" class="m-2" placeholder="请选择审批节点" size="small" style="width: 30%">
                            <el-option
                                v-for="item in jd_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>

                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="save_new_user"> 确认 </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <!--    修改弹窗-->
        <div>
            <el-dialog
                v-model="dialogVisible_update"
                title="修改审批节点"
                width="60%"
            >
                <div>
                    <div style="margin-top: 3%">
                        <el-tag>审批节点</el-tag>
                        <el-select v-model="update_jd" class="m-2" placeholder="请选择审批节点" size="small" style="width: 30%">
                            <el-option
                                v-for="item in jd_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>

                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible_update = false">取消</el-button>
                        <el-button type="primary" @click="update_user"> 确认 </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
    name: "admin_index",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            bdst: '',
            save_bdst: '',
            bdsts: [
                {
                    value: '1',
                    label: '项目公司',
                },
                {
                    value: '21',
                    label: '2标一工区',
                },
                {
                    value: '22',
                    label: '2标二工区',
                },
                {
                    value: '51',
                    label: '5标一工区',
                },
                {
                    value: '52',
                    label: '5标二工区',
                },
                {
                    value: '53',
                    label: '5标三工区',
                },
                {
                    value: '54',
                    label: '5标四工区',
                },
                {
                    value: '55',
                    label: '5标五工区',
                },
                {
                    value: '56',
                    label: '5标六工区',
                },
                {
                    value: '57',
                    label: '5标七工区',
                }
            ],
            user_list: [],
            dialogVisible: false,
            bdst_user_list: [],
            bdst_user: '',
            jd: '',
            jd_list: [
                {
                    value: '0',
                    label: '工区设备部长',
                },
                {
                    value: '1',
                    label: '工区主管',
                },
                {
                    value: '2',
                    label: '项目公司设备部',
                },
                {
                    value: '3',
                    label: '项目公司分管',
                },
                {
                    value: '4',
                    label: '工区设备部部部员',
                }
            ],
            dialogVisible_update: false,
            update_id: '',
            update_jd: ''
        }
    },
    created() {
        this.getAllUser()
    },
    methods: {
        // 获取所有授权人员
        getAllUser(){
            this.axios.get('/materialDbdUser/getAllUser', (response) => {
                this.user_list = response.obj
            },{
                bdst: this.bdst,
	              material_equiment: 1
            })
        },
        // 新增、修改节点
        save_jd(){
            this.dialogVisible = true
        },
        save_new_user(){
            this.axios.post('/materialDbdUser/saveUserJd', (response) => {
                if (response.obj){
                    ElMessage({
                        message: '新增成功',
                        type: 'success'
                    })
                    this.save_bdst = ''
                    this.bdst_user = ''
                    this.jd = ''
                }else {
                    ElMessage.error('新增失败，请联系科信部!')
                }
            },{
                bdst: this.save_bdst,
                user_name: this.bdst_user.split(',')[1],
                user_id: this.bdst_user.split(',')[0],
                jd: this.jd,
	              material_equiment: 1
            })
            console.log(this.bdst_user.split(','))
            console.log(this.save_bdst)
            console.log(this.jd)
        },
        // 根据工区获取人员信息
        getUserByBdst(){
            this.axios.get('/userinfo/getUserByBdst', (response) => {
                this.bdst_user_list = response.obj
            },{
                bdst: this.save_bdst
            })
        },
        // 弹出修改节点弹窗
        update_jd(item){
            this.dialogVisible_update = true
            this.update_id = item.id
        },
        // 修改审批节点
        update_user(){
            this.axios.post('/materialDbdUser/updateById', (response) => {
                if (response.obj) {
                    ElMessage({
                        message: '修改成功',
                        type: 'success'
                    })
                }
            },{
                id: this.update_id,
                jd: this.update_jd
            })
        }

    }
}
</script>

<style scoped>

</style>
