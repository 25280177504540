<template>
  <div>
    <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">
      <!--  类别-->
      <div>
        <el-tag>类别</el-tag>
        <el-input v-model="dbd_type" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入类别"/>
      </div>
      <!--  编号-->
      <div style="margin-left: 10%;margin-right: 10%">
        <el-tag>编号</el-tag>
        <el-input v-model="dbd_num" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入编号"/>
      </div>
      <!--  时间-->
      <div>
        <el-tag>时间</el-tag>
        <el-date-picker
            style="margin-left: 1%;width: 60%;"
            v-model="dbd_time"
            type="date"
            placeholder="请选择时间"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="small"
        />
      </div>
	    <div style="margin-left: 10%;margin-right: 10%" v-if="this.GET_BDST === '53'">
		    <el-tag style="margin-right: 1%">调出工区</el-tag>
		    <el-select v-model="dc_bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 60%">
			    <el-option
				    v-for="item in dc_bdsts"
				    :key="item.value"
				    :label="item.label"
				    :value="item.value"
			    />
		    </el-select>
	    </div>
      <div style="margin-left: 10%;margin-right: 10%" v-if="GET_BDST !== '53'">
        <el-tag style="margin-right: 1%">调入单位</el-tag>
        <el-select v-model="dr_bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 60%">
          <el-option
              v-for="item in bdsts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </div>
	    <div style="margin-top: 2%" v-if="GET_BDST === '53'">
		    <el-tag style="margin-right: 1%">调入单位</el-tag>
		    <el-select v-model="dr_bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 74%">
			    <el-option
				    v-for="item in bdsts"
				    :key="item.value"
				    :label="item.label"
				    :value="item.value"
			    />
		    </el-select>
	    </div>
    </div>
    <el-divider />
  <!--  材料列表-->
	  <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;">
		  <div>
			  <el-tag style="width: 60%;text-align: center">名称</el-tag>
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center">型号</el-tag>
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center">单位</el-tag>
			  <!--          <el-input v-model="material_unit[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入单位"/>-->
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center">数量</el-tag>
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center">单价(元)</el-tag>
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center">金额(元)</el-tag>
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center">备注</el-tag>
		  </div>
		  <div>
			  <el-tag style="width: 60%;text-align: center" size="small" type="danger" @click="add_list">✚</el-tag>
		  </div>

	  </div>
    <div v-for="(index, item) in material_list" :key="index" style="width: 100%;margin-top: 1%">
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;">
        <div>
          <el-input v-model="material_name[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="机械设备名称"/>
        </div>
        <div>
          <el-input v-model="material_model[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="规格型号"/>
        </div>
        <div>
          <el-select v-model="material_unit[index]" class="m-2" placeholder="请选择单位" size="small" style="width: 60%;margin-left: 1%">
            <el-option
              v-for="item in units"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
<!--          <el-input v-model="material_unit[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入单位"/>-->
        </div>
        <div>
          <el-input v-model="material_num[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入数量" @blur="countMoney(index)"/>
        </div>
	      <div>
		      <el-input v-model="material_no_taxrate[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="不含税单价(元)" @blur="countMoney(index)"/>
	      </div>
	      <div>
		      <el-input v-model="material_money[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="不含税金额(元)"/>
	      </div>
	      <div>
		      <el-input v-model="remarks[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入备注"/>
	      </div>

      </div>
      <el-divider />
	    <div>
		    <el-upload
		    	class="upload-demo"
		    	action="https://www.cr12cz.cn/czscsms/up/uploadMaterial"
		    	:auto-upload="true"
		    	:on-remove="handleRemove"
		    	:on-change="imgchange"
		    	:on-success="upsuccess"
		    	:multiple="false">
			    <el-button type="primary">选择附件并提交</el-button>
		    </el-upload>
	    </div>
    </div>
    <!--<el-button type="primary" @click="test">提交</el-button>-->
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import {setNowDate, setNowTimes} from "@/utils/time";
export default {
  name: "index",
  inject: ['reload'],
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER'])
  },
  data() {
    return {
      dbd_num: '',
      dbd_type: '',
      dbd_time: '',
      material_list: [0],
      material_name: [],
      material_model: [],
      material_unit: [],
      material_num: [],
      material_no_taxrate: [],
      material_money: [],
      remarks: [],
      created_time: [],
      dr_bdst: '',
      bdsts: [
        {
          value: '21',
          label: '2标一工区',
        },
	      {
		      value: '22',
		      label: '2标二工区',
	      },
        {
          value: '23',
          label: '2标三工区',
        },
        {
          value: '51',
          label: '5标一工区',
        },
        {
          value: '52',
          label: '5标二工区',
        },
        {
          value: '53',
          label: '5标三工区',
        },
        {
          value: '54',
          label: '5标四工区',
        },
        {
          value: '55',
          label: '5标五工区',
        },
	      {
		      value: '56',
		      label: '5标六工区',
	      },
        {
          value: '57',
          label: '5标七工区',
        },
	      {
		      value: '1',
		      label: '5标项目经理部',
	      }
      ],
	    dc_bdst: '',
	    dc_bdsts: [
		    {
			    value: '23',
			    label: '2标三工区',
		    },
		    {
			    value: '53',
			    label: '5标三工区',
		    },
	    ],
      units: [
        {
          value: '吨',
          label: '吨',
        },
        {
          value: 'm',
          label: 'm',
        },
        {
          value: 'm²',
          label: 'm²',
        },
        {
          value: 'm³',
          label: 'm³',
        },
        {
          value: '个',
          label: '个',
        },
        {
          value: '卷',
          label: '卷',
        },
        {
          value: '根',
          label: '根',
        },
        {
          value: '件',
          label: '件',
        },
        {
          value: '套',
          label: '套',
        },
	      {
		      value: '批',
		      label: '批',
	      }
      ],
	    fileList: [],
	    dataup: {
		    file: '',
		    userid: ''
	    },
    }
  },
  created() {
		this.dc_bdst = this.GET_BDST
    this.remarks[0] = ' '
      this.dbd_time = setNowDate(new Date())
  },
  methods: {
    add_list(){
      this.material_list.push(this.material_list[this.material_list.length - 1] + 1)
      this.remarks[this.material_list[this.material_list.length - 1]] = ' '
    },
	  countMoney(index){
			this.material_money[index] = this.material_num[index] * this.material_no_taxrate[index]
	  },
    test(){
      //console.log(this.remarks)
      this.axios.post('/materialDbdMain/createdMaterialDbd', (response) => {
        if (response.obj){
          ElMessage({
            message: '创建成功',
            type: 'success'
          })
          this.reload()
          this.axios.get('/materialDbdMain/getLcFlagById', (response1) => {
            for (let i = 0; i < response1.obj.length; i++){
              this.axios.post('/wxSendMessage/sendDbdSp', (response2) => {

              },{
                user_id: response1.obj[i]
              })
            }
          },{
            id: response.obj2,
	          material_equiment: 1
          })
        }
      },{
        material_name: this.material_name,
        material_model: this.material_model,
        material_unit: this.material_unit,
        material_num: this.material_num,
        material_no_taxrate: this.material_no_taxrate,
        material_money: this.material_money,
        remarks: this.remarks,
        created_time: setNowTimes(new Date()),
        bdst: this.dc_bdst,
        created_name: this.GET_USER,
        dbd_type: this.dbd_type,
        dbd_num: this.dbd_num,
        dbd_time: this.dbd_time,
        dr_bdst: this.dr_bdst,
	      material_equiment: 1
      })
    },
	  imgchange(file) {
		  this.fileList.push(file);
	  },
	  handleRemove(file) {
		  for (let i = 0; i < this.fileList.length; i++) {
			  if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
				  this.fileList.splice(i, 1)
			  }
		  }
	  },
	  upsuccess(response) {
		  if (response.obj) {
			  this.axios.post('/materialDbdMain/createdMaterialDbd', (response1) => {
				  if (response1.obj){
					  ElMessage({
						  message: '创建成功',
						  type: 'success'
					  })
					  this.reload()
					  this.axios.get('/materialDbdMain/getLcFlagById', (response2) => {
						  for (let i = 0; i < response2.obj.length; i++){
							  this.axios.post('/wxSendMessage/sendDbdSp', (response2) => {

							  },{
								  user_id: response2.obj[i]
							  })
						  }
					  },{
						  id: response1.obj2,
						  material_equiment: 1
					  })
				  }
			  },{
				  material_name: this.material_name,
				  material_model: this.material_model,
				  material_unit: this.material_unit,
				  material_num: this.material_num,
				  material_no_taxrate: this.material_no_taxrate,
				  material_money: this.material_money,
				  remarks: this.remarks,
				  created_time: setNowTimes(new Date()),
				  bdst: this.dc_bdst,
				  created_name: this.GET_USER,
				  dbd_type: this.dbd_type,
				  dbd_num: this.dbd_num,
				  dbd_time: this.dbd_time,
				  dr_bdst: this.dr_bdst,
				  material_equiment: 1,
				  file_url: response.obj
			  })
		  }
	  }
  }
}
</script>

<style scoped>

</style>
